<template>
  <div id="companyCss">
    <BaseBread style="margin-left: 5vh;">
      <BaseBreadItem to="/">首页</BaseBreadItem>
      <BaseBreadItem>企业荣誉</BaseBreadItem>
    </BaseBread>
    <div style="background-color: #fff">
      <!--  企业荣誉   -->
      <CompanyHonor/>
      <!--  知识产权   -->
      <CompanyProperty/>
    </div>
  </div>
</template>
<script>
import BaseBread from "@/components/library/base-bread.vue";
import BaseBreadItem from "@/components/library/base-bread-item.vue";
import CompanyHonor from "@/views/honor/components/company-honor.vue";
import CompanyProperty from "@/views/honor/components/company-property.vue";


export default {
  components: {
    CompanyProperty, CompanyHonor, BaseBreadItem, BaseBread
  },
  setup() {

  },
}

</script>
<style scoped lang="less">
#companyCss {
  background-color: #FFF;
}
</style>
